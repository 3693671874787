import React from "react";
import { graphql, Link } from "gatsby";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Tobics from "../components/tobics-side-bar";
import Img from "gatsby-image";
import Layout from "../components/layout";
import { renderRichText } from "gatsby-source-contentful/rich-text";
const Text = ({ children }) => <p className="align-center">{children}</p>;
/* eslint react/prop-types: 0 */
export const query = graphql`
  query($slug: String!) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      author {
        profilePicture {
          fixed(width: 60, quality: 100, height: 60) {
            width
            height
            src
            srcSet
          }
        }
        name
      }
      createdAt(formatString: "MM/DD/YYYY")
      featuredImage {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid
        }
      }
      category {
        slug
        title
      }
      content {
        raw
        references {
          contentful_id
          __typename
          fluid(maxWidth: 1600) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

const postTemplate = ({ data: { post } }) => (
  <Layout>
    <section className=" flex my-32 gap-4  mobile:block tablet:block laptop:mx-8 mobile:m-8 tablet:m-8">
      <Tobics />
      <div className="max-w-4xl my-8 mx-auto ">
        <h1 className="text-4xl font-bold ">{post.title}</h1>
        <p className=" text-sm text-gray-400 my-3">
          Posted {post.createdAt} in &nbsp;
          <Link
            to={`/blog/${post.category[0].slug}`}
            className={"text-blue-600"}
          >
            {post.category[0].title}
          </Link>
        </p>
        {/*<Img className="w-1/2 mx-auto" fluid={data.contentfulBlogPost.node.featuredImage.fluid} alt="Man Using his laptop and happy with Hostylus services" />*/}
        {post.featuredImage && (
          <Img
            className="featured"
            fluid={post.featuredImage.fluid}
            alt={post.title}
          />
        )}
        {post.author && (
          <div className="flex gap-4 my-6">
            <img
              className="rounded-full"
              src={post.author.profilePicture.fixed.src}
              alt={post.author.name}
            />
            <h2 className="text-gray-400 my-auto text-sm">
              By &nbsp;
              <span className="text-blue-600 font-bold">
                {post.author.name}
              </span>
            </h2>
          </div>
        )}

        <div>
          {post.content &&
            renderRichText(post.content, {
              renderNode: {
                [INLINES.ENTRY_HYPERLINK]: function alink(node, children) {
                  {
                    return <Link to={node.data.uri}>{children}</Link>;
                  }
                },
                [BLOCKS.EMBEDDED_ENTRY]: function con() {
                  // console.log("embedded entry", node);
                  return (
                    <>
                      <h2>Embedded Entry</h2>
                      {/* Using gatsby-image */}
                      <Img fixed={post.content.references.fixed} />
                      {/* Using img */}
                      <img src={post.content.references.fixed.src} />
                    </>
                  );
                },
                [BLOCKS.EMBEDDED_ASSET]: function n(node) {
                  // console.log("embedded entry", node);

                  // return <Img fixed={...node.data.target} />;
                  return <Img fluid={node.data.target.fluid} />;
                },
                [BLOCKS.UL_LIST]: function ulitems(node, children) {
                  return (
                    <ul
                      style={{
                        listStyle: "disc",
                        fontSize: "15px",
                        lineHeight: "30px",
                        marginLeft: "1rem",
                      }}
                    >
                      {children.map((item) => (
                        <li key={item.key}>
                          {item.props.children[0].props.children[0]}
                        </li>
                      ))}
                    </ul>
                  );
                },
                [BLOCKS.PARAGRAPH]: function par(node, children) {
                  return <Text>{children}</Text>;
                },
                [BLOCKS.HEADING_1]: function hed1(node, children) {
                  return <h1>{children}</h1>;
                },
                [BLOCKS.HEADING_2]: function hed2(node, children) {
                  return <h2>{children}</h2>;
                },
                [BLOCKS.HEADING_3]: function hed3(node, children) {
                  return <h3>{children}</h3>;
                },
                [BLOCKS.HEADING_4]: function hed4(node, children) {
                  return <h4>{children}</h4>;
                },
                [BLOCKS.HEADING_5]: function hed5(node, children) {
                  return <h5>{children}</h5>;
                },
                [BLOCKS.HEADING_6]: function hed6(node, children) {
                  return <h6>{children}</h6>;
                },
              },
              [INLINES.HYPERLINK]: function a_href(node, children) {
                return (
                  <a
                    href={node.data.uri}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#c0c0c0" }}
                  >
                    {children}
                  </a>
                );
              },
            })}
        </div>
      </div>
    </section>
  </Layout>
);

export default postTemplate;
